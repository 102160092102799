import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/2016-04-18-indexeddb",
  "date": "2016-04-18",
  "title": "INDEXEDDB",
  "author": "admin",
  "tags": ["development", "javascript", "indexeddb"],
  "featuredImage": "feature.jpg",
  "excerpt": "IndexedDB stands for Indexed Database, is a web standard for storing large amounts of client-side data in a structured manner. This low-level API is able to index data which enables performance rich search on data."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h2>{`Why IndexedDb?`}</h2>
    <p>{`Before IndexedDb, there was another storage specification called `}<inlineCode parentName="p">{`Web SQL`}</inlineCode>{`. The W3C announced it is going to be deprecated and IndexedDb should be used as the alternative.`}</p>
    <p>{`IndexedDb comes with the following features:`}</p>
    <ul>
      <li parentName="ul">{`Asynchronous API`}</li>
      <li parentName="ul">{`Stores key-value pairs`}</li>
      <li parentName="ul">{`Not SQL`}</li>
      <li parentName="ul">{`Non-relational`}</li>
      <li parentName="ul">{`Index based search API`}</li>
      <li parentName="ul">{`Same domain data access`}</li>
    </ul>
    <h2>{`Why not cookies?`}</h2>
    <p>{`Cookies can support only a limited space and not ideal for storing fairly large values. Every time a request is sent, the cookies need to be sent back and forth as well.`}</p>
    <h2>{`How about Local Storage?`}</h2>
    <p>{`It provides more storage than cookies, but still limited. It also doesn’t have a proper search API as data is stored as key value pairs.`}</p>
    <h2>{`Getting Ready`}</h2>
    <p>{`Before we start using IndexedDb feature, we need to check if the browser supports IndexedDb. We can simply do this with the following code:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var idbSupported = false;
 
document.addEventListener("DOMContentLoaded", function(){
 
  window.indexedDB = window.indexedDB || window.mozIndexedDB ||
    window.webkitIndexedDB || window.msIndexedDB;
 
  if (!window.indexedDB) {
    idbSupported = true;
    console.info('Your browser does not support IndexedDB.');
  } else {
    console.info('IndexedDB is supported');
  }
 
}, false);
`}</code></pre>
    <p>{`The code snippet above checks if the browser you are using supports IndexedDb, and we do this check on the `}<inlineCode parentName="p">{`DOMContentLoaded`}</inlineCode>{` event which gets fired when the document has finished loading.`}</p>
    <h2>{`Opening an IndexedDB Database`}</h2>
    <p>{`IndexedDB databases are local to the browser which is only available to the user. and the database is unique for the domain it was loaded from.`}</p>
    <p>{`The basic usage for opening a database is you provide the name and importantly the version.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if(idbSupported) {
  var db = indexedDB.open('vehicles', 1);
}
`}</code></pre>
    <p>{`We make sure IndexedDB is supported, if so we open the database named `}<inlineCode parentName="p">{`vehicle`}</inlineCode>{`.`}</p>
    <p>{`In IndexedDB, opening a database operation is asynchronous. To handle the result of this kind of operations we need to write event handlers. There are four types of events that can be fired.`}</p>
    <ul>
      <li parentName="ul">{`success`}</li>
      <li parentName="ul">{`error`}</li>
      <li parentName="ul">{`upgradeneeded`}</li>
      <li parentName="ul">{`blocked`}</li>
    </ul>
    <p>{`Let’s incorporate some of these event handlers to our code.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`if(idbSupported) {
  var db = indexedDB.open('vehicles', 1);
 
  openRequest.onupgradeneeded = function(event) {
      console.log("Upgrading IndexedDB");
  }
 
  openRequest.onsuccess = function(e) {
      console.log("Success!");
      db = e.target.result;
  }
 
  openRequest.onerror = function(e) {
      console.log("Error");
      console.dir(e);
  }
}
`}</code></pre>
    <p>{`This will not do anything yet, because we don’t have the vehicles database yet. Let’s create one,`}</p>
    <h2>{`Adding Data`}</h2>
    <p>{`Before we start, let’s create some dummy data.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`var vehicles = [
  { id: '01', make: 'BMW', brand: 'X5', doors: 5 },
  { id: '02', make: 'Audi', brand: 'A5', doors: 5 }
];
`}</code></pre>
    <p>{`Next let’s create a function for adding data to the database.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function add(store, name, entry, index) {
   var transaction = store.transaction([name], 'readwrite');
   var objectStore = transaction.objectStore(name);
   var request = objectStore.add(entry, index);
 
   request.onsuccess = function(event) {
      console.log('%s has been added to IndexedDB', name);
   };
 
   request.onerror = function(event) {
      console.log('Unable to add %s\\r\\nIt already exists in IndexedDB!');
   }
}
`}</code></pre>
    <p>{`Now we will update the `}<inlineCode parentName="p">{`onsuccess`}</inlineCode>{` method to insert data to the database.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`openRequest.onsuccess = function(event) {
    console.log("Success!");
 
    var store = event.target.result;
 
    var vehicles = [
      { id: '01', make: 'BMW', brand: 'X5', doors: 5 },
      { id: '02', make: 'Audi', brand: 'A5', doors: 5 }
    ];
 
    vehicles.forEach(function(vehicle, index) {
      add(store, 'vehicles', vehicle, index);
    });
}
`}</code></pre>
    <p>{`Open the `}<inlineCode parentName="p">{`index.html`}</inlineCode>{` file on your browser and then the developer tools (F9 or Cmd+Alt+I). You will see the following messages in the console as well as the entries in the IndexedDB database.`}</p>
    <pre><code parentName="pre" {...{}}>{`IndexedDB is supported

Success!

vehicles has been added to IndexedDB
`}</code></pre>
    <h2>{`Retrieve Data`}</h2>
    <p>{`Just like adding data to IndexedDB, we can retrieve data using `}<inlineCode parentName="p">{`get()`}</inlineCode>{` method.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-js"
      }}>{`function read(store, name, entry) {
   var transaction = store.transaction([name]);
   var objectStore = transaction.objectStore(name);
   var request = objectStore.get(entry);
 
   request.onsuccess = function(event) {
     if(request.result) {
       console.log(
         'Make: %s Brand: %s', request.result.make,
         request.result.brand);
     } else {
        console.log('Unable to find the key %s', entry);
     }
   };
 
   request.onerror = function(event) {
      console.log(
        'Unable to retrive %s\\r\\nIt already exists in IndexedDB!',
        entry
      );
   }
}
`}</code></pre>
    <p>{`Before testing this code, if you have run the code before, make sure you clear the `}<inlineCode parentName="p">{`vehicles`}</inlineCode>{` table in the DevTools resource tab.`}</p>
    <p>{`Now the console output will look like the following:`}</p>
    <pre><code parentName="pre" {...{}}>{`IndexedDB is supported

Success!

vehicles has been added to IndexedDB

Make: BMW Brand: X5
`}</code></pre>
    <h2>{`Conclusion`}</h2>
    <p>{`IndexedDB brings basic database capability to the browser, making it possible to build web applications that work online and off. It does, however, require shifting your mind a bit, and becoming familiar with database concepts.`}</p>
    <p>{`You can find the source code for this article in `}<a parentName="p" {...{
        "href": "https://github.com/szaranger/indexeddb-intro"
      }}>{`Github`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      